import React, { useState, useEffect } from "react";
import chargingStation1 from "../images/suvchargingstation.jpg";
import solarGarage from "../images/solar garage.jpg";
import solarStorage from "../images/solarstorage.jpg";
import controlRoom from "../images/controlRoom.jpg";

export default function GridandCharger() {
    const [activeSection, setActiveSection] = useState(0);

    const handleScroll = () => {
        const sections = document.querySelectorAll(".section");
        let index = 0;
        sections.forEach((section, i) => {
            const rect = section.getBoundingClientRect();
            const sectionMidpoint = rect.height / 2;
            if (rect.top <= sectionMidpoint && rect.bottom >= sectionMidpoint) {
                index = i;
            }
        });
        setActiveSection(index);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const texts = [
        {
            title: "Off Grid Solar Charging Station",
            description: "Our off-grid solar charging stations are designed to harness the power of the sun, providing a sustainable and reliable energy source for electric vehicles, even in remote areas."
        },
        {
            title: "Solar Garage",
            description: "Our solar garages combine functionality with sustainability, offering covered parking spaces equipped with solar panels to charge your electric vehicle directly from the sun."
        },
        {
            title: "Solar Charging System",
            description: "This advanced solar charging system integrates cutting-edge technology to ensure efficient energy storage and distribution, making clean energy accessible and reliable."
        },
        {
            title: "Control Room",
            // description: "The control room is the brain of our operations, where all systems are monitored and managed to ensure optimal performance and safety across our solar infrastructure."
            description: "The control room is to manage our fleets, offices and charging hubs"

        }
    ];

    const images = [chargingStation1, solarGarage, solarStorage, controlRoom];

    return (
        <div className="chargers my-24">
            <div className="container grid md:grid-cols-12 gap-8 px-8 mx-auto">
                <div className="text-container md:col-span-4 sticky top-0 h-screen flex items-center">
                    <div className="text text-4xl font-semibold text-center text-gray-800">
                        {texts.map((item, index) => (
                            <div
                                key={index}
                                className={`transition-transform bg-white -mb-10 rounded-sm bg-opacity-85 duration-500 ease-in-out ${
                                    activeSection === index
                                        ? "scale-100 opacity-100"
                                        : "scale-75 opacity-0"
                                }`}
                            >
                                <h1 className="text-[#6be675] font-black">
                                    {item.title}
                                </h1>
                                <p className="text-lg text-gray-600">
                                    {item.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="image-sections md:col-span-8 space-y-4">
                    {images.map((image, index) => (
                        <div key={index} className="section h-screen">
                            <img
                                src={image}
                                alt={`Charging Station ${index + 1}`}
                                className="object-cover w-full h-full rounded-[100px] shadow-lg"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
