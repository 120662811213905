import React from 'react';
import cash from "../images/cash.jpg"
import momo from "../images/momo.png"
import pos from "../images/pos.png"
import online from "../images/online.jpg"

export default function PaymentSystem() {
  return (
    <section className='bg-gray-100 py-20'>
        <h3 className='heading-text text-center mx-auto container px-8'>Choose from a variety of <span className='text-bg'>Payment Methods</span> to complete your transaction.</h3>
        <div className='grid grid-cols-1 md:grid-cols-4 py-10 gap-8 container px-8 mx-auto'>
            <div className='flex flex-col text-center justify-center items-center'>
                <img src={cash} alt="momo" className='text-center w-[320px] shadow-lg rounded-md h-[200px]' />
                <p className='text-center my-4 text-lg'>Physical Cash</p>
            </div>
            <div className='flex flex-col text-center justify-center items-center'>
                <img src={pos} alt="momo" className='text-center w-[320px] shadow-lg rounded-md h-[200px]' />
                <p className='text-center my-4 text-lg'>P.O.S</p>
            </div>
            <div className='flex flex-col text-center justify-center items-center'>
                <img src={momo} alt="momo" className='text-center w-[320px] shadow-lg rounded-md h-[200px]' />
                <p className='text-center my-4 text-lg'>Momo</p>
            </div>
            <div className='flex flex-col text-center justify-center items-center'>
                <img src={online} alt="momo" className='text-center w-[320px] shadow-lg rounded-md h-[200px]' />
                <p className='text-center my-4 text-lg'>Online Bank / Credit Card Payment</p>
            </div>
        </div>
    </section>
  );
}
