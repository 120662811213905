import React from 'react';
import woman from "../images/woman driving.jpg"
import automatedCarWash from "../images/automatedCarwashing.jpg"
import mechanics from "../images/mechanics.jpg"

export default function TwoColumnGrid() {
    const messages = [
        {
            title: "Discounts and Bonuses",
            text: "We are offering a 10/20% discount on our rides, along with a referral bonus for our clients."
        },
        {
            title: "Empowering Women",
            text: "Did you know that 40% of our staff are women? This helps us meet the world SDG target and also gain more female clients due to our female drivers inclusion."
        },
        {
            title: "Corporate Social Responsibility",
            text: "We are committed to 0.01% corporate social responsibility for climate change education, EV cars education, road safety, and supporting orphanage homes (including fees for brilliant but needy students)."
        },
        {
            title: "Ride Sharing",
            text: "If 2 passengers share the same area and destination within 2 metre radius, they will also share the ride cost."
        },
        {
            title: "Rapid Maintenance",
            text: "Our rapid maintenance team is always on standby to ensure smooth operations."
        },
        {
            title: "Automated Car Washing",
            text: "We have automated car washing machines to keep our fleets clean and ready."
        }
    ];

    return (
        <>
            <h3 className="heading-text text-center md:mb-10 mb-20">Our Commitment to <span className='text-bg'>Excellence</span>  and <span className='text-bg'>Sustainability</span></h3>
            <div className="container mx-auto py-24 px-8 grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="image-container relative h-full">
                    <img 
                        src={automatedCarWash} 
                        alt="car wash" 
                        className="absolute border-[15px] border-white right-0 top-1/2 transform -translate-y-1/2 w-10/12 h-auto rounded-lg shadow-lg"
                    />
                    <img 
                        src={woman} 
                        alt="women riding" 
                        className="absolute -rotate-[20deg] border-[15px] border-white left-0 top-0 w-7/12 h-auto rounded-lg"
                    />
                    <img 
                        src={mechanics} 
                        alt="mechanics
                        " 
                        className="absolute rotate-[5deg] border-[15px] border-white left-0 bottom-0 w-6/12 h-auto rounded-lg"
                    />
                </div>
                <div className="text-container space-y-4 text-lg mt-40 text-gray-800">
                    {messages.map((message, index) => (
                        <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                            <p className="font-bold text-white bg-[#6be675] p-2 mb-2">{message.title}:</p>
                            <p>{message.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>

    );
}
