import { useState, useEffect, useRef } from "react";
import Logo from "../images/esmartlogo png.png";
import { FaBars, FaTimes } from "react-icons/fa"; // Import icons from react-icons

export default function NavigationBar() {
  // State to track the scroll position
  const [isFixed, setIsFixed] = useState(false);
  
  // State to toggle the visibility of mobile navigation items
  const [isNavVisible, setIsNavVisible] = useState(false);

  // Ref to access the mobile menu DOM element
  const mobileMenuRef = useRef(null);

  // Effect to handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Effect to handle clicks outside the mobile menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsNavVisible(false);
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Function to toggle navigation items visibility
  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <nav className={`navbar shadow-lg ${isFixed ? 'fixed' : 'absolute'} top-0 left-0 z-50 w-full ${isFixed ? 'bg-white' : 'bg-transparent'} opacity-[0.95] transition-all duration-300 tracking-widest`}>
      <div className="nav-items container mx-auto flex justify-between items-center  px-8">
        <a href="/">
          <img className="py-4" src={Logo} width={70} height={70} alt="Logo" />
        </a>
        <div className="hidden lg:flex gap-x-16 items-center">
          <a href="#home" className={`${isFixed ? 'text-stone-900' : 'text-white'} transition-colors duration-300`}>Home</a>
          <a href="#about-us" className={`${isFixed ? 'text-stone-900' : 'text-white'} transition-colors duration-300`}>About</a>
          <a href="#services" className={`${isFixed ? 'text-stone-900' : 'text-white'} transition-colors duration-300`}>Services</a>
          <a href="#contact" className={`${isFixed ? 'text-stone-900' : 'text-white'} transition-colors duration-300`}>Contact</a>
        </div>
        <div className="block lg:hidden">
          <button onClick={toggleNav} className="text-2xl">
            {isNavVisible ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      <div
        ref={mobileMenuRef}
        className={`fixed top-0 left-0 w-full bg-white shadow-lg transition-transform transform ${isNavVisible ? 'translate-y-16 opacity-100' : '-translate-y-full opacity-0'} md:hidden`}
      >
        <div className="flex flex-col items-center py-4">
          <a href="#home" className="py-2 text-lg">Home</a>
          <a href="#about" className="py-2 text-lg">About</a>
          <a href="#services" className="py-2 text-lg">Services</a>
          <a href="#contact" className="py-2 text-lg">Contact</a>
        </div>
      </div>
    </nav>
  );
}
