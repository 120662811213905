import './App.css';
import Footer from './Components/Footer';
import GridandCharger from './Components/GridandChargers';
import Header from './Components/Header';
import NavigationBar from './Components/NavigationBar';
import ProblemSolution from './Components/ProblemSolution';
import Services from './Components/Services';
import ProblemSlides from './Components/ProblemSlides';
import Features from './Components/Features';
import PaymentSystem from './Components/paymentSystem';

export default function Home() {
  return (
    <>
      {/* navigation bar */}
      <NavigationBar />

      {/* header */}
      <Header />

      {/* problems and solutions */}
      <ProblemSolution />

      {/* our services */}
      <Services />


      {/* grid and charging */}
      <GridandCharger />

      {/* Features */}
      <Features />

      {/* payment */}
      <PaymentSystem />

      {/* footer */}
      <Footer />
    </>
  );
}



// <>
//       <NavigationBar />
//       <header className="header-bg relative w-screen h-[1200px]">
//         <div className="header-content container w-screen px-4 mx-auto relative overflow-hidden flex flex-col items-start justify-center h-full -mt-52">
//           <div className="text-wrapper text-black flex space-x-2 text-4xl -mt-[250px] md:-mt-[200px] md:text-7xl lg:text-8xl font-bold overflow-hidden z-50">
//             {/* Wrap each letter in a span */}
//             <span className="letter animate-slide-in-delay">E</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.1s" }}>-</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.1s" }}>S</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.2s" }}>M</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.3s" }}>A</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.4s" }}>R</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.5s" }}>T</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.6s" }}>R</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.7s" }}>I</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.8s" }}>D</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.9s" }}>E</span>
//             <span className="letter animate-slide-in-delay" style={{ opacity: "0", animationDelay: "1s" }}>S</span>
//           </div>
//           <img draggable className="absolute lg:top-64 top-[580px] suvImage lg:-right-52" src={suv} />
//           <div className="text-stone-600 text-4xl mt-6 mb-2 flex text-left text-sub overflow-hidden relative h-[50px] w-[600px]">
//             <h3 className="text-left text-xl lg:text-3xl go-green">Go Green, The Smart Way</h3> 
//           </div>
//           <div className="absolute bottom-[200px] lg:bottom-[150px] left-[50%] translate-x-[-50%]  ">
//             <button className="color-changing-bg px-4 py-2 text-xl rounded-md font-bold text-white">Get Started</button>
//           </div>
//         </div>
//       </header>

//       {/* about */}
//       <AboutPage />

//       {/* services */}
//       <Services />

//       {/* business model */}
//       <BusinessModel />

//       {/* footer */}
//       <Footer />
//     </>