import React, { useState, useEffect } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import ProblemSlides from './ProblemSlides';
import SlideSolutions from './SolutionSlides';

export default function ProblemSolution() {
    // State to keep track of the active tab
    const [activeTab, setActiveTab] = useState("tab1");

    // Effect to handle changes when the active tab state changes
    useEffect(() => {
        // Example side-effect: logging the active tab
        console.log("Active tab changed to:", activeTab);
    }, [activeTab]);

    return (
        <section className='container px-8 w-screen mx-auto'>
            <div cla className='md:mt-24 pb-24'>
                <h2 className='text-center heading-text'>Key <span className='text-bg'>Challenges</span> with Innovative <span className='text-bg'>Solutions</span></h2>
                <p className='paragraph'>We recognize the unique challenges and obstacles that arise in the ride-hailing industry. We have pinpointed several key issues affecting our sector and are dedicated to confronting them directly. Our strategy includes a thorough examination of these problems, followed by the creation and deployment of effective, forward-thinking solutions. By harnessing advanced technology and strategic expertise, we seek to turn these challenges into opportunities for advancement and success. Discover how our solutions are poised to create meaningful impact and foster positive change.</p>
                    <Tabs.Root
                        className=''
                        defaultValue="tab1"
                        orientation="vertical"
                        onValueChange={setActiveTab}  // Update state on tab change
                    >
                        <Tabs.List aria-label="tabs example">
                            <Tabs.Trigger
                                className={`border mx-2 mt-10 mb-2 px-4 py-1 rounded-xl ${activeTab === "tab1" ? 'bg-slate-800 text-white' : 'bg-gray-50'}`}
                                value="tab1"
                            >
                                Challenges
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className={`border mx-2 mt-10 mb-2 px-4 py-1 rounded-xl ${activeTab === "tab2" ? 'bg-slate-800 text-white' : 'bg-gray-50'}`}
                                value="tab2"
                            >
                                Solutions
                            </Tabs.Trigger>
                        </Tabs.List>
                        <Tabs.Content value="tab1">
                            <ProblemSlides />
                        </Tabs.Content>
                        <Tabs.Content value="tab2">
                            <SlideSolutions />
                        </Tabs.Content>
                    </Tabs.Root>
            </div>
        </section>
    );
}


