

import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import longWaiting from "../images/long waiting queue.jpg"
import surge from "../images/surge.jpg"
import cancelRides from "../images/cancelRide1.jpg"
import angryMan from "../images/angryman.jpg"
import fumes from "../images/fumes.jpg"
import dirtyCar from "../images/dirtycarr.jpg"
import emergency from "../images/emergency.jpg"
import routes from "../images/routes.jpg"
import tornseats from "../images/tornseats.jpg"

const slides = [
    {
      image: longWaiting,
      title: "1. Lack of Reliable and Affordable Transportation for Urban Africa",
      description: "As urban populations in Africa continue to grow, the demand for dependable and cost-effective transportation options intensifies. Many residents struggle with inconsistent services and high costs. Our solution focuses on providing reliable, affordable ride-hailing options tailored to the needs of these expanding urban areas, ensuring that everyone has access to quality transportation."
    },
    {
      image: surge,
      title: "2. High Ride Fares and Surge Pricing",
      description: "Surge pricing and elevated fare rates can make ride-hailing unaffordable for many users, especially during peak times. We are addressing this by implementing fair pricing strategies and transparent fare structures that aim to balance affordability with driver compensation, making rides more accessible without compromising service quality."
    },
    {
      image: cancelRides,
      title: "3. Arbitrary Ride Request Denials by Drivers",
      description: "It’s frustrating for users when drivers arbitrarily deny ride requests, causing delays and inconvenience. Our approach includes setting clear guidelines and accountability measures for drivers, ensuring that ride requests are handled fairly and efficiently, improving the overall user experience."
    },
    {
      image: angryMan,
      title: "4. Limited Ride Availability and Long Wait Times",
      description: "Long wait times and limited ride availability can hinder the convenience of ride-hailing services. We are enhancing our platform with improved algorithms and increased driver-partner availability to reduce wait times and ensure that users can quickly find a ride when they need one."
    },
    {
      image: fumes,
      title: "5. Air and Noise Pollution from Fossil Fuel Engines",
      description: "Fossil fuel engines contribute significantly to air and noise pollution, impacting urban environments. Our solution emphasizes the integration of electric vehicles into our fleet to reduce emissions and noise, contributing to a cleaner and quieter cityscape."
    },
    {
      image: dirtyCar,
      title: "6. Nuisance from Some Drivers and Unhygienic/uncomfortable Vehicles",
      description: "Unpleasant experiences with drivers and unhygienic vehicles can deter users from using ride-hailing services. We are implementing strict cleanliness standards and driver training programs to ensure a high level of professionalism and vehicle hygiene, enhancing overall customer satisfaction."
    },
    {
      image: emergency,
      title: "7. No Hotline for Emergency Bookings",
      description: "In urgent situations, the lack of a dedicated hotline for booking rides can be a major inconvenience. We are introducing a customer support hotline that allows users to book rides quickly in emergencies, providing an additional layer of convenience and safety."
    },
    {
      image: routes,
      title: "8. Limited Ride Customization and Untracked Routes",
      description: "The lack of customizable ride options and route tracking can diminish the user experience. Our platform offers enhanced customization features and real-time route tracking, allowing users to tailor their rides to their preferences and monitor their journey for added peace of mind."
    },
    {
      image: tornseats,
      title: "9. Lack of In-Car Amenities for Client Comfort",
      description: "Comfort during a ride is often overlooked, with many vehicles lacking basic amenities. We are enhancing our service by incorporating in-car amenities designed to improve passenger comfort, such as climate control, clean interiors, and entertainment options, making each ride a more enjoyable experience."
    }
  ];
  

const SlideProblem = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const props = useSpring({
    opacity: 1,
    transform: `translateX(-${currentIndex * 100}%)`,
    config: { duration: 500 }
  });

  return (
    <div className="slider-container relative">
      <div className=" slider-wrapper" style={{ overflow: 'hidden' }}>
        <animated.div className="slider-content flex" style={props}>
          {slides.map((slide, index) => (
            <div key={index} className="slide grid grid-cols-1 md:grid-cols-2 gap-12 p-8 flex-shrink-0 w-full">
              <img src={slide.image} alt={slide.title} className="w-full h-auto shadow-lg rounded-xl" />
              <div className='h-auto flex flex-col items-center justify-center'>
                <h3 className='sub-heading-text text-4xl'>{slide.title}</h3>
                <p className='paragraph'>{slide.description}</p>
              </div>
            </div>
          ))}
        </animated.div>
      </div>
      <button onClick={handlePrev} className="slide-button absolute -left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-4 py-2">
        &lt;
      </button>
      <button onClick={handleNext} className="slide-button absolute -right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-4 py-2">
        &gt;
      </button>
    </div>
  );
};

export default SlideProblem;

