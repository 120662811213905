import React from 'react';
import logo from "../images/esmartlogo png.png"
export default function Footer() {
  return (
    <footer id='contact' className="bg-gray-800 text-gray-200 py-12">
      <div className="container mx-auto px-4 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="flex flex-col">
            <h4 className="text-xl font-semibold mb-4">Company</h4>
            <img src={logo} alt='logo' width={100} className='my-2 bg-white p-2' />
            <p className="mb-4">E-Smart Rides provides eco-friendly ride-hailing services in Ghana and beyond. We are committed to making transportation smarter and greener.</p>
            <address>
              <p className="mb-2">AS 28 (ENNIS STREET, AK-679-0652)</p>
              <p className="mb-2">Kumasi, Ghana</p>
              <p className="mb-2">Email: <a href="mailto:esmartrides@gmail.com" className="text-green-400 hover:underline">esmartrides@gmail.com</a></p>
              <p>Phone 1: <a href="tel:+233541758595" className="text-green-400 hover:underline">+233 54 175 8595</a></p>
              <p>Phone 2: <a href="tel:+233501542088" className="text-green-400 hover:underline">+233 50 154 2088</a></p>
            </address>
          </div>

          {/* Quick Links */}
          <div className="flex flex-col">
            <h4 className="text-xl font-semibold mb-4">Quick Links</h4>
            <ul>
              <li className="mb-2">
                <a href="#home" className="text-gray-400 hover:text-green-400">Home</a>
              </li>
              <li className="mb-2">
                <a href="#about-us" className="text-gray-400 hover:text-green-400">About Us</a>
              </li>
              <li className="mb-2">
                <a href="#services" className="text-gray-400 hover:text-green-400">Services</a>
              </li>
              <li className="mb-2">
                <a href="#contact" className="text-gray-400 hover:text-green-400">Contact</a>
              </li>
            </ul>
          </div>

          {/* Follow Us */}
          <div className="flex flex-col">
            <h4 className="text-xl font-semibold mb-4">Follow Us</h4>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M22 12.1c0 5.5-4.5 10-10 10s-10-4.5-10-10 4.5-10 10-10 10 4.5 10 10zm-12.3-2.5h1.7c.5 0 .9.4.9.9v1.7h-2.6v-1.7c0-.5.4-.9.9-.9zm.2 1.8h-.1v1.2h1.7v.6h-1.7v1.7h-1.5v-1.7h-1.7v-1.2h1.7v-1.2c0-1.1.9-1.7 1.7-1.7zm7.1-1.2c-1.4 0-2.7.4-3.8 1.2-1 1-1.6 2.3-1.6 3.8v1.3h-1.2v-2h-1.2v-1.2h1.2v-1.2c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1v1.2h-1.2v1.2h-1.2v1.2h1.2v2h-1.2v-1.3c0-1.4-.6-2.7-1.6-3.7-1-1-2.4-1.6-3.8-1.6zm4.7 1.8h-.1v1.2h.1c.7 0 1.2-.6 1.2-1.2s-.5-1.2-1.2-1.2h-.1zm-.1 1.8h-.1v1.2h.1v-1.2zm-.1-1.2c-.5 0-.9.4-.9.9v1.2h1.7v-1.2c0-.5-.4-.9-.9-.9z"/></svg>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M23 3c-.8.4-1.7.7-2.6.8 1-1 1.7-2.6 1.7-4.2-1 .6-2.1 1-3.3 1.2-1-1-2.4-1.6-3.9-1.6-3 0-5.5 2.5-5.5 5.5 0 .4 0 .8.1 1.2-4.6-.2-8.7-2.4-11.4-5.7-.5.8-.8 1.7-.8 2.7 0 1.9 1 3.6 2.4 4.6-0.9 0-1.8-.3-2.6-.7v.1c0 2.6 1.9 4.8 4.4 5.3-0.5.1-1.1.2-1.6.2-0.4 0-0.8 0-1.2-.1 0.8 2.5 3.1 4.3 5.8 4.3-2.1 1.7-4.8 2.7-7.6 2.7-0.5 0-1-.1-1.5-.1 2.9 1.9 6.4 3 10.1 3 12.1 0 18.7-10 18.7-18.7 0-0.3 0-0.7-0.1-1 1.3-1 2.4-2.3 3.3-3.7z"/></svg>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2.2c3.6 0 4.1.01 5.6.1 1.5.1 2.8.7 3.8 1.7.9.9 1.6 2.2 1.7 3.8.1 1.5.1 2.5.1 5.6s0 4.1-.1 5.6c-.1 1.5-.7 2.8-1.7 3.8-.9.9-2.2 1.6-3.8 1.7-1.5.1-2.4.1-5.6.1s-4.1 0-5.6-.1c-1.5-.1-2.8-.7-3.8-1.7-.9-.9-1.6-2.2-1.7-3.8-.1-1.5-.1-2.4-.1-5.6s0-4.1.1-5.6c.1-1.5.7-2.8 1.7-3.8.9-.9 2.2-1.6 3.8-1.7 1.5-.1 2.5-.1 5.6-.1zm0-2.2c-3.7 0-4.1.01-5.6.1-1.5.1-2.8.7-3.8 1.7-.9.9-1.6 2.2-1.7 3.8-.1 1.5-.1 2.4-.1 5.6s0 4.1.1 5.6c.1 1.5.7 2.8 1.7 3.8.9.9 2.2 1.6 3.8 1.7 1.5.1 2.5.1 5.6.1s4.1 0 5.6-.1c1.5-.1 2.8-.7 3.8-1.7.9-.9 1.6-2.2 1.7-3.8.1-1.5.1-2.4.1-5.6s0-4.1-.1-5.6c-.1-1.5-.7-2.8-1.7-3.8-.9-.9-2.2-1.6-3.8-1.7-1.5-.1-2.5-.1-5.6-.1zM12 7.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5zm0 7.5c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm4.6-6.7c-.4 0-.7-.3-.7-.7s.3-.7.7-.7.7.3.7.7-.3.7-.7.7z"/></svg>
              </a>
            </div>
          </div>

          {/* Download the App */}
          <div className="flex flex-col">
            <h4 className="text-xl font-semibold mb-4">Download the App</h4>
            <div className="flex space-x-4">
              <a href="https://play.google.com/store/apps/details?id=com.ridehailing" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <img width={10} height={10} src="/images/google-play-badge.png" alt="Get it on Google Play" className="w-32"/>
              </a>
              <a href="https://apps.apple.com/us/app/ridehailing/id123456789" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <img width={10} height={10} src="/images/app-store-badge.png" alt="Download on the App Store" className="w-32"/>
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 border-t border-gray-700 pt-4 text-center">
          <p className="text-gray-400 text-sm">© 2024 E-Smart Rides. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
